<template lang="pug">
    div#executivos
        .wrapper
            h3 Conheça nossos executivos
            ul
                li(v-for="i in 8")
                    .img
                        img(:src="require('@/assets/images/temp/placeholder-executivos.png')")
                    .description
                        h4 MARLON V. ROCHA
                        p Executivo de Incorporação
</template>

<script>
export default {
}
</script>

<style lang="stylus" scoped src="./Executivos.styl"></style>