<template lang="pug">
	section#legado
		div.wrapper
			div.fundador
				figure
					img(:src="require('@/assets/images/legado/geninho.png')", alt="Geninho Thomé").geninho
				div.content
					h3 Fundador
					p
						strong NEGÓCIOS SÓ FAZEM SENTIDO SE MUDAREM A VIDA DAS PESSOAS.
					p Geninho Thomé, fundador e presidente da GT Company, sempre foi fascinado por desafios e pelo propósito de mudar a vida das pessoas.
						|  Por isso, depois de muito estudo – dentro e fora do país –, desenvolveu os primeiros implantes de qualidade em titânio,
						|  proporcionando tratamento dentário acessível para todos. Foi assim que o Dr. Geninho criou a Neodent, líder da categoria na América Latina.
						|  Empreendedor por natureza, foi além e transferiu sua experiência para a GT Company e fez da companhia uma grande holding com investimentos nos setores de tecnologia,
						|  saúde, imobiliário, além de investimentos em diversas áreas.
					h4
						| Dr. Geninho
						br
						| Thomé
			div.sucessao
				figure
					img(:src="require('@/assets/images/legado/joaoalfredo.png')", alt="João Alfredo Thomé").joao
				div.content
					h3 Sucessão
					p
						strong UMA VISÃO INOVADORA E REFINADA, PASSADA DE PAI PARA FILHO.
					p Empreendedor moderno e com conceitos inovadores, João dedica sua vida a construir grandes projetos e relacionamentos de confiança e segurança com cada cliente.
						|  Com visão estratégica aliada à responsabilidade, ele segue a visão inovadora de trabalho de Geninho Thomé, que se refina na convivência entre mestre e aprendiz, entre pai e filho.
					h4
						| João
						br
						| Alfredo Thomé
			.buttons
				button.conheca(@click="anchorTo('#yachthouse', 'empresas')") 
					|Conheça nossas empresas
</template>

<script>
	export default {
		name: 'section-legado',
		methods: {
			anchorTo(to, option = null) {
				this.$scrollTo(to, () => {
					if(option != null)
						this.$openGaleria(option)
				})
			}
		},
	}
</script>

<style lang="stylus" scoped src="./Legado.styl"></style>
