import { render, staticRenderFns } from "./Historia.vue?vue&type=template&id=58343715&scoped=true&lang=pug&"
import script from "./Historia.vue?vue&type=script&lang=js&"
export * from "./Historia.vue?vue&type=script&lang=js&"
import style0 from "./Historia.styl?vue&type=style&index=0&id=58343715&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58343715",
  null
  
)

export default component.exports