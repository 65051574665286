<template lang="pug">
    div#cidade
        img(:src="require('@/assets/images/background/bg_predio.png')", alt="Cidade")
</template>

<script>
export default {
    
}
</script>

<style lang="stylus" scoped src="./Cidade.styl"></style>