<template lang="pug">
    ul.redes
        // li.facebook
        //     a
        //         include ../../assets/svgs/icon_facebook.svg
        li.twitter
            a(href="https://www.instagram.com/gtcompanyoficial/", target="_blank")
                include ../../assets/svgs/icon_instagram.svg
        li.linkedin
            a(href="https://www.linkedin.com/company/gt-gtcompany/", target="_blank")
                include ../../assets/svgs/icon_linkedin.svg
</template>

<script>
export default {};
</script>

<style lang="stylus" scoped src="./Redes.styl"></style>
