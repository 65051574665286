<template lang="pug">
	article#pageHome.home
		// Frases

		Redes
		Banner
		Yachthouse
		section#galerias
			// div.wrapper
			Historia
			include ../../assets/svgs/letterings/tudoTemUmProposito.svg
		Legado
		//Executivos
		LerMais(apiRoute="conteudo/por-dentro")
		Contato
</template>

<script>

	import Redes from '@/components/Redes/Redes.vue'
	import Frases from '@/components/Frases/Frases.vue'

	import LerMais from '@/components/LerMais/LerMais.vue'

	import Banner from '@/sections/Home/Banner/Banner.vue'
	import Historia from '@/sections/Home/Historia/Historia.vue'

	import Legado from '@/sections/Home/Legado/Legado.vue'
	import Contato from '@/sections/Home/Contato/Contato.vue'
	import Executivos from '@/sections/Home/Executivos/Executivos.vue'
	import Yachthouse from '@/sections/Home/Yachthouse/Yachthouse.vue'

	// import Scenes from '@/mixins/scenes.js'

	export default {
		name: 'view-home',
		components: {
			Frases,
			Redes,
			Banner,
			Historia,
			Legado,
			Executivos,
			Contato,
			LerMais,
			Yachthouse,
		},
		// mixins: [
		// 	Scenes
		// ],
		mounted() {
			// const event = new Event('initHome');
			// window.dispatchEvent(event);
			if (this.$route.params && this.$route.params.option)
				this.$openGaleria(this.$route.params.option)
		},
		methods: {
			anchorTo(to, option = null) {
				this.$scrollTo(to, () => {
					if (option != null)
						this.$openGaleria(option)
				})
			}
		},
	}
</script>

<style lang="stylus" scoped src="./Home.styl"></style>
