<template lang="pug">
    ul#frases
        li(v-for="frase in frases", ref="frases") {{ frase.frase }}
</template>

<script>
export default {
    data() {
        return {
            frases: [
                {
                    frase: 'Negócios Inteligentes',
                    positionY: 150,
                    velocidade: 2
                },
                {
                    frase: 'Energia',
                    positionY: 200,
                    velocidade: 4
                },
                {
                    frase: 'Que Transformam Vidas',
                    positionY: 375,
                    velocidade: 3
                },
                {
                    frase: 'Consultoria',
                    positionY: 900,
                    velocidade: 2
                },
                {
                    frase: 'Educação',
                    positionY: 1000,
                    velocidade: 4
                },
                {
                    frase: 'Mudança de Vida',
                    positionY: 1100,
                    velocidade: 3
                },
            ],
            scrollElement: null
        }
    },
    methods: {
        doScroll() {
            // if (parseFloat(this.$refs.frases[5].style.top) > 0) {
            //     console.log(parseFloat(this.$refs.frases[5].style.top))
            //     $event.preventDefault();
            // }
                
            this.$refs.frases.forEach((frase, index) => {
                const position = this.frases[index].positionY;
                const velocidade = this.frases[index].velocidade;

                if (position > document.querySelector('.home').innerHeight)
                    frase.style.top = position + (100 * (index * .75)) - document.querySelector('html').scrollTop * velocidade + 'px';
                else
                    frase.style.top = position - document.querySelector('html').scrollTop * velocidade + 'px';
                // console.log(frase)
            })
        }
    },
    created() {
        // window.addEventListener('initHome', () => {
        setTimeout(() => {
            document.addEventListener('scroll', this.doScroll)
        }, 250)
        // });
    }, 
    mounted() {
        this.$refs.frases.forEach((frase, index) => {
            frase.style.top = this.frases[index].positionY + 'px';
        })
    },
}
</script>

<style lang="stylus" scoped src="./Frases.styl"></style>