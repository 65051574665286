<template lang="pug">
    div.descubra
        figure.logo
            img(:src="logo.img", :alt="logo.alt")

        div.content
            h3 {{ conteudo.titulo }}
            p(v-html="conteudo.texto")
</template>

<script>
export default {
    props: {
        logo: {
            type: Object,
            default: () => { 
                return {
                    img: "",
                    alt: ""
                }
            }
        },
        conteudo: {
            type: Object,
            default: () => { 
                return {
                    titulo: "",
                    texto: ""
                }
            }
        }
    }
}
</script>

<style lang="stylus" scoped src="./DescricaoGaleria.styl"></style>