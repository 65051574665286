<template lang="pug">
    div#historia
        div.wrapper
            div.aGt
                figure.logo
                    img(:src="require('@/assets/images/logo/logo_gtcompany.png')")

                div.content
                    h3 NEGÓCIOS INTELIGENTES
                    p 
                        | Um negócio inteligente é mais seguro, por mais desafiador que seja. 
                        | É uma oportunidade que ganha vida quando o capital investido não 
                        | é simplesmente financeiro, é humano.
                    // button(type="button") Assista ao vídeo
            
            div.timeline
                button(@click="toggleGaleria(galeriaAtivo - 1)").btnTimeline.prev
                    include ../../../assets/svgs/arrow_white.svg
                button(@click="toggleGaleria(galeriaAtivo + 1)").btnTimeline.next
                    include ../../../assets/svgs/arrow_white.svg
                div.time
                    ul.datas(:style="timelineStyle")
                        li(v-for="index in 3", :data-ano="lastItems[index - 1].data.ano" :class="{penultimo: index == galeriaAtivo + 3, antepenultimo: index == galeriaAtivo + 2}", @click="toggleGaleria(galeriaAtivo - 1)")
                            p.ano {{ lastItems[index - 1].data.ano }}
                            p.mes {{ lastItems[index - 1].data.mes }}
                            i
                        li(v-for="time, index in timeline", :data-ano="time.data.ano" :class="{ativo: index == galeriaAtivo, penultimo: index == galeriaAtivo - 1, antepenultimo: index == galeriaAtivo - 2}", ref="datasList", @click="toggleGaleria(index)")
                            p.ano {{ time.data.ano }}
                            p.mes {{ time.data.mes }}
                            i
                        li(v-for="index in 3", :data-ano="timeline[index - 1].data.ano" :class="{ativo: index == galeriaAtivo}", @click="toggleGaleria(galeriaAtivo + index + 1)")
                            p.ano {{ timeline[index - 1].data.ano }}
                            p.mes {{ timeline[index - 1].data.mes }}
                            i
                div.galeria
                    transition-group(name="descricaoTimeline", :duration="1000")
                        div.galeria-item(v-for="time, index in timeline", v-if="index == galeriaAtivo", :key="`${time.data.ano}-${time.data.mes}-${time.descricao.titulo}-desc`")
                            div.descricao
                                h4 {{ time.descricao.titulo }}
                                p(v-html="time.descricao.texto")
                    transition-group(name="figureTimeline", :duration="1000")
                        div.galeria-item(v-for="time, index in timeline", v-if="index == galeriaAtivo", :key="`${time.data.ano}-${time.data.mes}-${time.descricao.titulo}-img`")
                            figure
                                img(:src="time.img")
</template>

<script>
export default {
    data() {
        return {
            galeriaAtivo: 0,
            timelineStyle: {
                transition: 'auto',
                transform: 'translateX(0)',
            },
            timeline: [
                {
                    descricao: {
                        titulo: "O INÍCIO",
                        texto: "Dr. Geninho Thomé começou a realizar estudos científicos para desenvolver implantes a um preço mais acessível. Nascia o que viria a transformá-lo em uma referência mundial. Dr. Geninho Thomé passou a desenvolver artesanalmente modelos de implantes em titânio."
                    },
                    data: {
                        ano: 1990,
                        // mes: "junho"
                    },
                    img: require('@/assets/images/historia/1990-1.png')
                },
                {
                    descricao: {
                        titulo: "FUNDAÇÃO DA NEODENT",
                        texto: "Dr. Geninho Thomé funda a Neodent, empresa que revolucionou o mercado dentário com implantes de alta qualidade a preço acessível para todos."
                    },
                    data: {
                        ano: 1993,
                        // mes: "junho"
                    },
                    img: require('@/assets/images/historia/1993-2.png')
                },
                {
                    descricao: {
                        titulo: "FUNDAÇÃO DA ILAPEO",
                        texto: "Dr. Geninho Thomé foi um dos fundadores do Instituto Latino Americano de Pesquisa e Ensino Odontológico. A ILAPEO é consequência dos cursos ministrados por ele desde 1993 e nasceu como braço de pesquisa e ensino odontológico da Neodent."
                    },
                    data: {
                        ano: 2004,
                        // mes: "junho"
                    },
                    img: require('@/assets/images/historia/2004-1.png')
                },
                {
                    descricao: {
                        titulo: "A NEOORTHO",
                        texto: "No mesmo ano, criou a Neoortho, fabricante de próteses ortopédicas e produtos para osteossíntese. Hoje, a companhia possui um complexo industrial com 2.000 m² em Curitiba/PR e duas sedes comerciais: uma internacional em Fort Myers/EUA e outra na capital paranaense."
                    },
                    data: {
                        ano: 2004,
                        // mes: "junho"
                    },
                    img: require('@/assets/images/historia/2004-2.png')
                },
                {
                    descricao: {
                        titulo: "Venda Parcial da Neodent",
                        texto: "Ao constatar o sucesso da Neodent, a Straumann, maior player do mercado de implantes do mundo, busca pela família Thomé e adquire 49% das ações da empresa."
                    },
                    data: {
                        ano: 2012,
                        // mes: "junho"
                    },
                    img: require('@/assets/images/historia/2012.png')
                },
                {
                    descricao: {
                        titulo: "NASCE O FAMILY OFFICE",
                        texto: "Dr. Geninho Thomé quis aplicar toda a inteligência utilizada na Neodent para ajudar as pessoas em negócios de outros setores. Por isso, criou a Lavoro: uma holding que abrange negócios próprios e parcerias, que mais tarde passaria a se chamar GT Company."
                    },
                    data: {
                        ano: 2013,
                        // mes: "outubro"
                    },
                    img: require('@/assets/images/historia/2013.png')
                },
                {
                    descricao: {
                        titulo: "Venda total da Neodent",
                        texto: "Com um trabalho sério e percebendo que seu legado estava em boas mãos, Dr. Geninho Thomé aceita a aquisição total da Neodent. Mesmo assim, manteve-se no cargo de presidente científico da empresa."
                    },
                    data: {
                        ano: 2015,
                        // mes: "julho"
                    },
                    img: require('@/assets/images/historia/2015.png')
                },
                {
                    descricao: {
                        titulo: "NASCE A GT COMPANY",
                        texto: "A concretização do trabalho do Dr. Geninho Thomé veio através do nascimento da GT Company, um escritório que não é apenas investidor: é parceiro de todos os negócios em que atua."
                    },
                    data: {
                        ano: 2019,
                        // mes: "julho"
                    },
                    img: require('@/assets/images/historia/2019.png')
                },
            ]
        }
    },
    mounted() {
        setTimeout(() => {
            this.positionTimeline(true)
        }, 100)
    },
    computed: {
        liWidth() {
            return this.$devinho.getWidth(this.$refs.datasList[0])
        },
        liMargin() {
            return this.$devinho.getMargin(this.$refs.datasList[0], 'right')
        },
        timelineLength() {
            return this.timeline.length;
        },
        lastItems() {
            let items = [];
            items.push(this.timeline[this.timelineLength - 3])
            items.push(this.timeline[this.timelineLength - 2])
            items.push(this.timeline[this.timelineLength - 1])
            return items;
        }
    },
    methods: {
        positionTimeline(inicio = false, index = 0, fake = false) {
            if (inicio) {
                this.timelineStyle = {
                    transition: 'auto',
                    transform: `translateX(${(this.liWidth * (this.timelineLength + 2)) + (this.liMargin * (this.timelineLength + 2))}px)`
                }
            } else if (!inicio && index < 0 && !fake) {
                // index = 0
                this.timelineStyle = {
                    transition: 'auto',
                    transform: `translateX(${(this.liWidth * (index + 4)) + (this.liMargin * (index + 4))}px)`
                }
            } else {
                this.timelineStyle = {
                    transition: 'all .75s cubic-bezier(1, .01, .61, .99)',
                    transform: `translateX(${(this.liWidth * (this.timelineLength - index + 2)) + (this.liMargin * (this.timelineLength - index + 2))}px)`
                }
            }
        },
        toggleGaleria(index = 0) {
            this.positionTimeline(false, index, true);
            if (index > this.timelineLength - 1) {
                setTimeout(() => {
                    this.positionTimeline(true)
                    this.galeriaAtivo = 0;
                }, 900)
            } else if (index < 0) {
                setTimeout(() => {
                    this.positionTimeline(false, index)
                    this.galeriaAtivo = this.timelineLength + index;
                }, 900)
            } else {
                this.galeriaAtivo = index;
            }
        }
    },
}
</script>

<style lang="stylus" scoped src="./Historia.styl"></style>