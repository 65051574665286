<template lang="pug">
	div#yachthouse
		.container
			ul.galeria
				li(v-for="item, index in yachthouse", :class="{'ativo': index == ativo}")
					img(:src="item.image")
			ul.nav(:style="styles" v-if="ativo != yachthouse.length - 1")
				li(v-for="item, index in yachthouseNav", :class="{'ativo': index == ativo}" @click="toggleGaleria(index)")
					img(:src="item.image")
			//- .logo(v-else)
			//- 	img(:src="require('@/assets/images/logo/logo_gtcompany_business.png')")
		.wrapper
			.controls
				button.back(@click="toggleGaleria(ativo - 1)")
					img(:src="require('@/assets/images/icons/seta-yacht.png')")
				button.next(@click="toggleGaleria(ativo + 1)")
					img(:src="require('@/assets/images/icons/seta-yacht.png')")
			.descricao
				.titulo
					h3 {{yachthouse[ativo].titulo}}
					p.sub {{yachthouse[ativo].subtitulo}}
				.texto
					h4 {{yachthouse[ativo].tag}}
					p.sub(v-if="yachthouse[ativo].subtitulo2") {{yachthouse[ativo].subtitulo2}}
					p.conteudo {{yachthouse[ativo].texto}}
						a(@click="anchorTo('#yachthouse', 'empresas')" v-if="yachthouse[ativo].tag == 'GT BUSINESS'") Descubra

		Empresas

</template>

<script>
	import Empresas from '@/sections/Home/Empresas/Empresas.vue'

	export default {
		components: {
			Empresas
		},
		data() {
			return {
				ativo: 0,
				styles: {},
				yachthouse: [
					{
						titulo: "O Yachthouse",
						subtitulo: "By Pininfarina",
						image: require('@/assets/images/yachthouse/yachthouse_inovacao.jpg'),
						tag: "INOVAÇÃO",
						subtitulo2: "Se não fosse fora do comum, não seria Yachthouse.\n",
						texto: "Com inovação arquitetônica e traços elegantes, o empreendimento é rico em dinamismo e movimento, onde a base, representada por uma quilha, aponta em direção ao mar. Os apartamentos, localizados em duas torres, contam com paredes de vidro e uma vista surpreendente. A área de lazer, distribuída em dois pavimentos, oferece várias opções de entretenimento para o melhor do seu bem-estar.",
					},
					{
						titulo: "O Yachthouse",
						subtitulo: "By Pininfarina",
						image: require('@/assets/images/yachthouse/yachthouse_design.jpg'),
						tag: "DESIGN",
						subtitulo2: "Desenho surpreendente em um dos destinos mais desejados do Brasil.\n",
						texto: "Inspirado no luxuoso universo náutico, o empreendimento foi planejado para contemplar a paixão pelo mar aliada à sofisticação e à qualidade, sempre rigorosamente aplicadas pela construtora. Um conceito singular por ser construído junto à Marina Tedesco, na Barra Sul, com vista magnífica para o mar, o verde da Mata Atlântica e o Rio Camboriú.\n",
					},
					{
						titulo: "O Yachthouse",
						subtitulo: "By Pininfarina",
						image: require('@/assets/images/yachthouse/yachthouse_infraestrutura.jpg'),
						tag: "INFRAESTRUTURA",
						subtitulo2: "Tudo o que você precisa para aproveitar paisagens de tirar o fôlego.",
						texto: "Um lugar de potencial incomparável. Esta região privilegiada é um paraíso para quem quer viver o melhor da vida. A facilidade de se locomover, seja por terra, mar ou ar, fazem do litoral norte de Santa Catarina um lugar singular propício para o lazer e para os negócios.\n",
					},
					{
						titulo: "O Yachthouse",
						subtitulo: "By Pininfarina",
						image: require('@/assets/images/yachthouse/yachthouse_localizacao.jpg'),
						tag: "Localização",
						subtitulo2: 'Onde todos querem estar, viver e aproveitar.',
						texto: "Situado na Barra Sul, o bairro mais nobre de Balneário Camboriú, onde as águas doces do rio se encontram com as salgadas do mar, está o Yachthouse by Pininfarina. Emoldurada pela natureza, a região apresenta os mais diferentes atrativos: do simples passeio nas areias da praia, passando pela alta gastronomia, ao lazer náutico. Um misto de urbanização e belas paisagens que conferem à Barra Sul ares paradisíacos.\n",
					},
					{
						titulo: "Um Propósito",
						subtitulo: "Diversas Áreas",
						image: require('@/assets/images/yachthouse/yachthouse_gtcompany.jpg'),
						tag: "GT BUSINESS",
						subtitulo2: 'Confira outros setores em que Geninho está a frente através do investimento e do capital humano.',
						texto: "",
					},
				]
			}
		},
		computed: {
			yachthouseNav() {
				return this.yachthouse.slice(0, this.yachthouse.length - 1)
			}
		},
		mounted() {
			this.startAutoPlay()
		},
		methods: {
			startAutoPlay() {
				this.autoplay = setTimeout(this.autoplayLoop, 4000)
			},
			stopAutoPlay() {
				clearTimeout(this.autoplay)
			},
			autoplayLoop() {
				this.toggleGaleria(this.ativo + 1)
				this.autoplay = setTimeout(this.autoplayLoop, 4000)
			},
			anchorTo(to, option = null) {
				this.$scrollTo(to, () => {
					if(option != null)
						this.$openGaleria(option)
				})
			},
			toggleGaleria(index) {
				if (index > this.yachthouse.length - 1)
					this.ativo = 0
				else if (index < 0)
					this.ativo = this.yachthouse.length - 1
				else
					this.ativo = index

				let calc = -95 * this.ativo

				this.styles = {transform: `translateX(${calc}px)`}
			}
		}
	}
</script>

<style lang="stylus" scoped src="./Yachthouse.styl"></style>
