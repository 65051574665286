<template lang="pug">
    div#pessoa
        img(:src="require('@/assets/images/legado/bg_geninho.png')", alt="Geninho Thomé")
</template>

<script>
export default {
    
}
</script>

<style lang="stylus" scoped src="./Pessoa.styl"></style>