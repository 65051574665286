<template lang="pug">
    div.galeria
        button(@click="toggleGaleria(galeriaAtivo - 1)").btnPartners.prev
            include ../../assets/svgs/arrow_white.svg
        button(@click="toggleGaleria(galeriaAtivo + 1)").btnPartners.next
            include ../../assets/svgs/arrow_white.svg
        p.counter 
            span.atual {{ (galeriaAtivo + 1) }}
            span.total /{{ galeria.length }}
        transition-group(name="galeriaParceiros", :duration="1000")
            div.itemGaleria(v-for="item, index in galeria", v-if="index == galeriaAtivo", :key="`${item.titulo}`")
                div.descricao
                    h4 {{ item.titulo }}
                    h5 {{ item.subtitulo }}
                    p(v-html="item.texto")

                figure
                    img(:src="item.img", :alt="`${item.titulo} - ${item.subtitulo}`")
</template>

<script>
export default {
    props: {
        galeriaAtivo: Number,
        galeria: Array
    },
    methods: {
        toggleGaleria(index = 0) {
            if (index > this.$props.galeria.length - 1)
                index = 0;
            else if (index < 0)
                index = this.$props.galeria.length - 1

            this.$emit('update:galeriaAtivo', index);
        }
    },
}
</script>

<style lang="stylus" scoped src="./GaleriaGaleria.styl"></style>