<template lang="pug">
	div#empresas
		img(:src="empresas[galeriaAtivo].bg.img", :alt="empresas[galeriaAtivo].titulo", :style="empresas[galeriaAtivo].bg.style").bgPartners
		div.wrapper.flex
			button(@click="$toggleGaleria('empresas')").voltar
				span Fechar
				include ../../../assets/images/svgs/close_icon.svg
			DescricaoGaleria(:logo="descricaoLogoPartners", :conteudo="descricaoConteudoPartners" v-if="empresas[galeriaAtivo].tag == 'Partners'")
			DescricaoGaleria(:logo="descricaoLogoBusiness", :conteudo="descricaoConteudoBusiness" v-if="empresas[galeriaAtivo].tag == 'Business'")
			GaleriaGaleria(:galeria="empresas", :galeriaAtivo.sync="galeriaAtivo")
</template>

<script>
import DescricaoGaleria from '@/components/DescricaoGaleria/DescricaoGaleria.vue'
import GaleriaGaleria from '@/components/GaleriaGaleria/GaleriaGaleria.vue'

export default {
	components: {
		DescricaoGaleria,
		GaleriaGaleria,
	},
	data() {
		return {
			galeriaAtivo: 0,
			descricaoLogoPartners: {
				img: require('@/assets/images/logo/logo_gtcompany_partners.png'), 
				alt: 'Gt Company Partners'
			},
			descricaoConteudoPartners: {
				titulo: 'Negócios em parceria', 
				texto: 'A GT Partners oferece muito mais do que grandes investimentos, são parcerias em que o Dr. Geninho Thomé pode contribuir com seu capital intelectual. Essas parcerias geram grandes projetos, criam ideias e o desafio de chegar cada vez mais longe para causar impacto positivo na vida das pessoas.'
			},
			descricaoLogoBusiness: {
				img: require('@/assets/images/logo/logo_gtcompany_business.png'), 
				alt: 'Gt Company Business'
			},
			descricaoConteudoBusiness: {
				titulo: 'Negócios próprios', 
				texto: 'A GT Business traz a essência e a visão de seu fundador, Dr. Geninho Thomé. Por isso, cada negócio tem o propósito de mudar a vida das pessoas de um jeito inteligente e único.'
			},
			empresas: [
				{
					tag: "Business",
					titulo: "NEOORTHO",
					subtitulo: "TECNOLOGIA & SAÚDE",
					texto: "A Neoortho é uma fabricante de implantes ortopédicos, com um portfólio composto por mais de 11 mil itens, em crescente expansão e com excelente aceitação no mercado.",
					img: require('@/assets/images/business/neoortho.jpg'),
					bg: {
						img: require('@/assets/images/business/neoortho.jpg'),
						style: {}
					}
				},
				{
					tag: "Business",
					titulo: "GT BUILDING",
					subtitulo: "EMPREENDIMENTOS IMOBILIÁRIOS",
					texto: "Incorporadora com empreendimentos inovadores que, pelo uso de tecnologia, inovação e qualidade construtiva, cria projetos de vida diferenciados para diferentes públicos.",
					img: require('@/assets/images/business/gtbuilding.jpg'),
					bg: {
						img: require('@/assets/images/business/gtbuilding.jpg'),
						style: {}
					}
				},
				{
					tag: "Business",
					titulo: "FACULDADE ILAPEO",
					subtitulo: "EDUCAÇÃO",
					texto: "Referência internacional em odontologia, a ILAPEO começou como braço de pesquisa e ensino odontológico da Neodent, empresa líder no setor de implantes dentários.",
					img: require('@/assets/images/business/ilapeo.jpg'),
					bg: {
						img: require('@/assets/images/business/ilapeo.jpg'),
						style: {}
					}
				},
				// {
				// 	tag: "Business",
				// 	titulo: "ECOMAIS",
				// 	subtitulo: "ENERGIA",
				// 	texto: "Os briquetes Ecomais são a evolução do carvão. São mais econômicos devido a seu rendimento e eficiência, além de mais ecológicos, pois sua queima tem chamas limpas e com menos fumaça.",
				// 	img: require('@/assets/images/business/ecomais.jpg'),
				// 	bg: {
				// 		img: require('@/assets/images/business/ecomais.jpg'),
				// 		style: {}
				// 	}
				// },
				{
					tag: "Partners",
					titulo: "YACHTHOUSE BY PININFARINA",
					subtitulo: "AS TORRES MAIS ALTAS DA AMÉRICA LATINA",
					texto: "O Yachthouse by Pininfarina, megaempreendimento de luxo, possui incríveis 280 metros de altura. Um marco da engenharia brasileira, viabilizado pela parceria entre a Pasqualotto e a GT Company.",
					img: require('@/assets/images/partners/yachhouse.jpg'),
					bg: {
						img: require('@/assets/images/partners/yachhouse.jpg'),
						style: {}
					}
				},
				{
					tag: "Partners",
					titulo: "VITRA BY PININFARINA",
					subtitulo: "LUXO E DESIGN",
					texto: "Premiado pelo American Architecture Awards e pelo German Design Award, o Vitra by Pininfarina lembra uma pista de automobilismo, com linhas dinâmicas, modernas e sofisticadas que transmitem a sensação de movimento.",
					img: require('@/assets/images/partners/vitra.jpg'),
					bg: {
						img: require('@/assets/images/partners/vitra.jpg'),
						style: {}
					}
				},
				{
					tag: "Partners",
					titulo: "LA CITTÀ BY PININFARINA",
					subtitulo: "IMPONENTE E INOVADOR",
					texto: "Um empreendimento inovador que traz uma nova linguagem visual, estrutural e conceitual. Seu projeto foi elaborado para oferecer o que há de melhor e mais atual no mercado da construção civil de alto padrão mundial.",
					img: require('@/assets/images/partners/lacitta.jpg'),
					bg: {
						img: require('@/assets/images/partners/lacitta.jpg'),
						style: {}
					}
				},
			]
		}
	}
}
</script>

<style lang="stylus" scoped src="./Empresas.styl"></style>