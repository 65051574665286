<template lang="pug">
	section#banner
		.gtcompany
			Pessoa
			.logo
				h1
					|GT. Company - Negócios Inteligentes
					img(:src="require('@/assets/images/logo/logo_gtcompany.png')", alt="GT. Company - Negócios Inteligentes")
			.buttons
				button.conheca(@click="anchorTo('#yachthouse', 'empresas')") 
					|Conheça nossas empresas
		.texto
			div.img
				img(:src="require('@/assets/images/background/bg-banner-predio.png')")
			div.conteudo
				h2 NEGÓCIOS INTELIGENTES COM GRANDES RESULTADOS
				p
					|Com uma ampla variedade de aplicações, a GT Company é muito mais que uma grande investidora. 
					|Nosso alto padrão de liquidez financeiro e ótimo retorno, nos certifica como uma holding sólida e 
					|segura. Mas, nosso foco sempre foi quebrar paradigmas e ir muito além. Por isso, acreditamos que 
					|negócios inteligentes não devem gerar apenas capital financeiro, mas também, desenvolver inovações para o 
					|capital humano. Afinal, é pensando nas pessoas que transformamos negócios.
		Cidade
</template>

<script>
import Pessoa from '@/components/Pessoa/Pessoa.vue'
import Cidade from '@/components/Cidade/Cidade.vue'

export default {
	components: {
		Pessoa,
		Cidade,
	},
	methods: {
		anchorTo(to, option = null) {
            this.$scrollTo(to, () => {
                if(option != null)
                    this.$openGaleria(option)
            })
        }
	}
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>